









































































import { Component, Vue } from "vue-property-decorator";
import ProductTable from "@/components/product/ProductTable.vue";
import Search from "@/components/reusable/Search.vue";
import Pagination from "@/components/reusable/table/Pagination.vue";
import Icon from "@/components/reusable/Icon.vue";
import UIkit from "uikit";
import ProductService from "@/services/product_service";
//import Toast from "@/components/reusable/Toast.vue";
import { EventBus } from "@/events/index";
import {
  ProductModel,
  ProductModelRequest,
  ProductRequestOptions
} from "@/models/product";
import { APIResponse } from "@/models/api_res";
import { StoreModule } from "@/store/types";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
import { namespace } from "vuex-class";
import MoveItem from "@/components/reusable/MoveItem.vue";
import { AuthError } from "@/services/error_service";
import MfrMenu from "../manufacturer/MfrMenu.vue";
import { MfrModel } from "@/models/mfr";
import { CategoryModel } from "@/models/category";
import MfrService from "@/services/mfr_service";
import CategoryService from "@/services/category_service";
import { DeleteObject } from "@/models/utility";
import ConfirmDelete from "../reusable/modals/ConfirmDelete.vue";
@Component({
  components: {
    ProductTable,
    Search,
    Pagination,
    Icon,
    MoveItem
    //Toast
  }
})
export default class Product extends Vue {
  protected productService = new ProductService();
  protected deleteData: ProductModel[] = [];
  protected failed: string[] = [];
  protected success: string[] = [];
  protected toast = false;
  protected messageHtml = "";
  protected products: ProductModel[] = [];
  protected disableDrag = false;
  protected pages = 1;
  protected currentPage = 1;
  protected query = "";
  protected moveDestination = {} as any;
  protected moveData: ProductModel[] = [];
  protected parentObject: any = "";
  protected categoryService = new CategoryService();
  protected mfrService = new MfrService();

  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;

  created() {
    this.setLoading(true);
    if (this.$route.query.q) {
      this.disableDrag = true;
    }
    const optionsObject = this.getRequestOptions();
    this.getProducts(optionsObject);
    //  if (this.$route.query.deleted) {
    //this.showDeleteToast();
    //   }
  }
  protected async showDeleteToast(): Promise<void> {
    this.showToast(
      `Manufacturer ${decodeURIComponent(
        this.$route.query.deleted as string
      )} deleted successfully.`
    );
  }

  mounted() {
    EventBus.$on(
      "deleteConfirmed",
      (id: number, name: string, final = false) => {
        this.deleteRequest(id, name, final);
      }
    );
    /** Global event listener for data deletion. Triggers & sends array of data selected for deletion through to confirmation modal.
     * This event is called from the <Delete> component (a child in the corresponding <Menu> component [@ex: <ProductMenu>, <MfrMenu>...]) and from the base <Table> component.
     */
    EventBus.$on("deleteRow", (data: ProductModel[]) => {
      this.deleteData = data;
      this.$modal.show(
        ConfirmDelete,
        { deleteData: this.deleteData, type: "product" },
        { height: "auto", adaptive: true, classes: "overflow" }
      );
      //   UIkit.modal(
      //     document.getElementById("delete-modal") as HTMLElement
      //   ).show();
      // });
    });
  }

  beforeDestroy() {
    EventBus.$off("deleteConfirmed");
    EventBus.$off("deleteRow");
    /** UIkit modals do not leave the DOM unless explicitly destroyed. Destroying them helps with buggy functionality due to dynamic data. This method loops through all of the modal ids and remove
     * them from the DOM upon vue's beforeDestroy() lifecycle hook.
     *
     * Note that typescript does not have definitions for many UIkit methods, hence //@ts-ignore flag.
     */
    const modals = [
      "#delete-modal",
      "#move-modal",
      "#confirm-moving-modal",
      "#add-model",
      "#save-modal"
    ];
    modals.forEach(selector => {
      const component = UIkit.modal(selector);
      if (component) {
        //@ts-ignore
        component.$destroy(true);
      }
    });
  }

  protected addNew(): void {
    if (this.$route.query.mfr) {
      this.$router.push("/product/new?mfr=" + this.$route.query.mfr);
    } else if (this.$route.query.category) {
      this.$router.push("/product/new?parent=" + this.$route.query.category);
    } else {
      this.$router.push("/product/new");
    }
  }

  protected move(data: ProductModel[]) {
    this.moveData = data;
    UIkit.modal(document.getElementById("move-modal") as HTMLElement).show();
  }

  protected setDestination(value: CategoryModel) {
    this.moveDestination = value;
  }

  protected async sendMoveRequest(
    newParent: number,
    id: number,
    name: string,
    oldDestination: CategoryModel | null = null,
    final = false
  ): Promise<void> {
    // this.setLoading(true);
    try {
      await this.productService.linkCategory(id, { category_id: newParent });
      this.success.push(name);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.failed.push(name);
        // EventBus.$emit("showError", err.message);
      }
    }
    if (oldDestination) {
      try {
        await this.productService.unlinkCategory(
          id,
          parseInt(oldDestination.id as string, 10)
        );
      } catch (err) {
        if (err instanceof AuthError) {
          AuthError.logout();
        } else {
          EventBus.$emit("showError", err.message);
        }
      }
    }
    if (final) {
      const options: ProductRequestOptions = this.getRequestOptions();
      this.getProducts(options);
      if (this.success.length > 0) {
        EventBus.$emit(
          "showSuccess",
          `${
            this.success.length > 1 ? "Products have" : "Product has"
          } been moved successfully.`,
          []
        );
      }
      if (this.failed.length > 0) {
        EventBus.$emit(
          "showFail",
          `The move request for the following ${
            this.failed.length > 1 ? "products" : "product"
          } failed. Please try again.`,
          this.failed
        );
      }
      this.success = [];
      this.failed = [];
      this.moveData = [];
    }
  }

  protected async getProducts(optionsObject?: {
    page?: number;
    offset?: number;
    filter?: string;
    q?: string;
    mfr?: number;
    category?: number;
  }): Promise<void> {
    if (!optionsObject) {
      optionsObject = { offset: 25 };
    } else {
      optionsObject.offset = 25;
    }
    try {
      const res: APIResponse = await this.productService.getProducts(
        optionsObject
      );
      this.products = res.results;
      this.pages = res.meta.total_pages;
      this.currentPage = res.meta.page;
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  /**
   * HTTP request for show/hide user actions, called once per selected row
   */

  public async editVisibility(ids: number[], isHidden: boolean): Promise<void> {
    this.setLoading(true);
    for (let i = 0; i < ids.length; i++) {
      try {
        await this.productService.saveProduct({ is_hidden: isHidden }, ids[i]);
      } catch (err) {
        if (err instanceof AuthError) {
          AuthError.logout();
        } else {
          EventBus.$emit("showError", err.message);
        }
      }
    }
    const options = this.getRequestOptions();
    this.getProducts(options);
    this.showToast(`Toggle visibility request complete.`);
  }

  /**
   * Checks URL for current query terms. This should be called on every get request unless user is requesting unfiltered data
   * The object returned varies based on the endpoint being accessed. See model for each request for more details.
   */
  protected getRequestOptions(): ProductRequestOptions {
    this.disableDrag = true;
    const optionsObject = {} as any;
    if (this.query || this.$route.query.q) {
      this.query = this.$route.query.q
        ? (this.$route.query.q as string)
        : this.query;
      optionsObject.q = this.query;
    }
    if (this.$route.query.page) {
      optionsObject.page = parseInt(this.$route.query.page as string, 10);
    }
    if (this.$route.query.mfr) {
      optionsObject.mfr = parseInt(this.$route.query.mfr as string, 10);
      this.getParentObject(optionsObject.mfr, "mfr");
    }
    if (this.$route.query.hidden) {
      optionsObject.hidden = true;
    }
    if (this.$route.query.empty) {
      optionsObject.empty = true;
    }
    if (this.$route.query.category) {
      optionsObject.category = parseInt(
        this.$route.query.category as string,
        10
      );
      this.getParentObject(optionsObject.category, "cat");
    }
    return optionsObject;
  }

  protected async getParentObject(
    id: number,
    type: "mfr" | "cat"
  ): Promise<void> {
    if (type === "cat") {
      this.parentObject = await this.getSingleCategory(id);
    } else {
      this.parentObject = await this.getSingleMfr(id);
    }
  }

  /**
   * Paginate method triggered by child <Pagination> component.
   * First, calls this.getRequestOptions() to retain any other options (like query term)
   * NOTE: page property in returned options object must be overwritten -- this.getRequestOptions will return the current page.
   *
   * Second, send options object into API get request to get appropriate page of data.
   *
   * Finally, add page query to url if it is not already there. This ensure the correct page query is retained and will fetch correct data if user refreshes.
   */
  protected paginate(page: number) {
    const options: ProductRequestOptions = this.getRequestOptions();
    options.page = page;
    this.getProducts(options);
    if (!this.$route.query.page || this.$route.query.page !== page.toString()) {
      this.$router.push({
        query: { ...this.$route.query, page: page.toString() }
      });
    }
  }

  public receiveSearchTerm(query: string): void {
    if (this.query !== query) {
      this.query = query;
      this.$router.push({ query: { ...this.$route.query, q: query } });
    }
  }
  /** Reset search by re-requesting unfiltered data */
  public reset(): void {
    this.query = "";
    const options = { ...this.$route.query };
    delete options.q;
    this.$router.push({ query: { ...options } });
  }

  /**
   * @param id id of item to be deleted
   * @param name name of item to be deleted, used in <Toast> confirmation
   * @param final optional, default: false, flags the final item in the request array; triggers <Toast> confirmation, refreshes data
   *
   * in the <{Path}Editor> component, @param final is not used.
   */
  protected async deleteRequest(
    id: number,
    name: string,
    final = false
  ): Promise<void> {
    this.setLoading(true);
    try {
      await this.productService.deleteProduct(id);
      this.success.push(name);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.failed.push(name);
        // EventBus.$emit("showError", err.message);
      }
    }
    if (final) {
      const optionsObject = this.getRequestOptions();
      this.getProducts(optionsObject);
      if (this.success.length > 0) {
        EventBus.$emit(
          "showSuccess",
          `${
            this.success.length > 1 ? "Products have" : "Product has"
          } been deleted successfully.`,
          []
        );
      }
      if (this.failed.length > 0) {
        EventBus.$emit(
          "showFail",
          `The delete request for the following ${
            this.failed.length > 1 ? "products" : "product"
          } failed. Please try again.`,
          this.failed
        );
      }
      this.success = [];
      this.failed = [];
      this.deleteData = [];
    }
  }

  protected closeToast(): void {
    this.toast = false;
    this.failed = [];
    this.success = [];
  }
  protected async getSingleCategory(id: number): Promise<CategoryModel> {
    let category = {} as CategoryModel;
    try {
      const res: CategoryModel = await this.categoryService.getSingleCategory(
        id
      );
      category = res;
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    return category;
  }

  protected async getSingleMfr(id: number): Promise<MfrModel> {
    let mfr = {} as MfrModel;
    try {
      const res: MfrModel = await this.mfrService.getSingleMfr(id);
      mfr = res;
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    return mfr;
  }

  protected showToast(msg: string): void {
    this.messageHtml = msg;
    this.toast = true;
  }
}
