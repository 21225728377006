





















































































































































































import { Component, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import ProductMenu from "@/components/product/ProductMenu.vue";
import Icon from "@/components/reusable/Icon.vue";
import Table from "@/components/reusable/table/Table.vue";
import ProductService from "@/services/product_service";
import { getImage } from "@/utility/helpers";
@Component({
  components: {
    draggable,
    ProductMenu,
    Icon
  }
})
export default class ProductTable extends Table {
  protected productService = new ProductService();
  protected getImage = getImage;
  @Watch("tableData", { deep: true })
  onDataChange(): void {
    this.resetAllCheckboxRowData();
  }

  public async visibilityRequest(id: number, hide: boolean): Promise<void> {
    this.$emit("editVisibility", [id], hide);
  }

  public visibilityRequestMultiple(hide: boolean): void {
    this.$emit("editVisibility", this.selected, hide);
    this.resetAllCheckboxRowData();
  }
}
